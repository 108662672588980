Pusher.logToConsole = true;

function highlightConversationAsUnreaded(out_channel_name) {

    //highlight the message with new message and show toast message with the message body and sender
    var chat_with_new_message = $('[data-channel-name="' + out_channel_name + '"]');
    var alert_sign = chat_with_new_message.find('.alert-sign:eq(0)');
    alert_sign.removeClass('fa fa-message').addClass('fa-solid fa-exclamation');
    chat_with_new_message.removeClass('btn-primary').addClass('btn-warning');
}

// Listen to all channels according to assigned users
var auth_channel_name = 'receiver_' + $("#auth_id").val();
Echo.channel(auth_channel_name)
    .listen('NewMessage', (e) => {
        var receiver_id = e.receiver_id;
        var conversation_id = e.conversation_id;
        var message_sender_id = e.sender_id;
        //in case auth user is sender disable notification
        if ($("#auth_id").val() != message_sender_id) {
            var out_channel_name = e.channelName;
            //notify only if conversation not activated
            if (!$("#startChatButton_" + conversation_id).hasClass('active_conversation')) {
                highlightConversationAsUnreaded(out_channel_name);
                handleNotification(e, out_channel_name, conversation_id, message_sender_id, receiver_id);
            }
        }
    });
//listen to unassigned conversations
Echo.channel('unassigned_conversation')
    .listen('NewMessage', (e) => {
        var conversation_id = e.conversation_id;
        var message_sender_id = e.sender_id;

        var out_channel_name = e.channelName;
        //notify only if conversation not activated
        if (!$("#startChatButton_" + conversation_id).hasClass('active_conversation')) {
            highlightConversationAsUnreaded(out_channel_name);
            handleNotification(e, out_channel_name, conversation_id, message_sender_id);
        }
    });

function handleNotification(e, out_channel_name, conversation_id, message_sender_id, receiver_id = '') {
    // Check if there are attachment URLs
    if (e.attachments_urls && e.attachments_urls.length > 0) {
        // Create a container for images
        var imagesContainer = document.createElement('div');

        // Create a container for images
        imagesContainer.style.display = 'flex';
        imagesContainer.style.justifyContent = 'center';

// Iterate through each attachment URL and create an image element
        e.attachments_urls.forEach(function (imageUrl) {
            var imgElement = document.createElement('img');
            imgElement.src = imageUrl;
            imgElement.style.maxWidth = '20%'; // Adjust styling as needed
            imgElement.style.margin = '5px'; // Add margin for spacing between images
            imagesContainer.appendChild(imgElement);
        });

        // Combine the images container with the toast message
        var toastContent = document.createElement('div');
        toastContent.appendChild(document.createTextNode('From: ' + e.sender_name + '\n' + e.body));
        toastContent.appendChild(document.createElement('br')); // Add a line break

        // Append the images container to the toast content
        toastContent.appendChild(imagesContainer);

        // Display the toast message with the custom content
        toastrElement = toastr.info(toastContent, {
            "positionClass": "toast-top-right",
            "timeOut": 120000, // 2 minutes
            "closeButton": true,
            "progressBar": true,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut",
            "preventDuplicates": true,
            "onclick": function () {
                // Define the behavior when the user clicks on the toast message
                window.location.href = '/chats?out_channel_name=' + out_channel_name + '&receiver_id=' + receiver_id + '&conversation_id=' + conversation_id;
            }
        });
    } else {
        // No attachment URLs, display a regular toast message
        toastrElement = toastr.info('From: ' + e.sender_name + '\n' + e.body, {
            onclick: function () {
                // Define the behavior when the user clicks on the toast message
                window.location.href = '/chats?out_channel_name=' + out_channel_name + '&receiver_id=' + receiver_id + '&conversation_id=' + conversation_id;
            }
        });
    }

    // Attach click event using jQuery
    $(toastrElement).click(function () {
        // Define the behavior when the user clicks on the toast message
        window.location.href = '/chats?out_channel_name=' + out_channel_name + '&receiver_id=' + receiver_id + '&conversation_id=' + conversation_id;
    });
}

$(document).on('click', '#kt_drawer_chat_close', function () {
    var currentElement = $(this);
    $("#kt_drawer_chat_livewire").removeClass('drawer-on');
    $("#kt_drawer_chat_livewire").addClass('drawer-end');
    //remove active class from closed conversation
    $("#startChatButton_" + currentElement.attr('data-conversation-id')).removeClass('active_conversation');
    livewire.emit('closeChat');
});

$(document).ready(function () {
    var channelName = ''; // Initialize channelName variable

    // Function to set the channel dynamically
    function setChannel(channel) {
        // Leave the current channel (if any)
        if (channelName) {
            Echo.leave(channelName);
        }

        channelName = channel;

        // Join the new channel
        Echo.channel(channelName)
            .listen('NewMessage', (e) => {
                var receiver_id = e.receiver_id;
                var conversation_id = e.conversation_id;
                var message_sender_id = e.sender_id;
                if ($("#auth_id").val() != message_sender_id) {
                    $("#alert_sign_" + conversation_id).removeClass('fa fa-message');
                    $("#alert_sign_" + conversation_id).addClass('fa-solid fa-exclamation');
                    $("#startChatButton_" + conversation_id).removeClass('btn-primary');
                    $("#startChatButton_" + conversation_id).addClass('btn-warning');
                    startChatWithUser(conversation_id, receiver_id);
                }
            });
    }

    $(document).on('click', '.startChatButton', function () {
        var sender_id = $(this).attr('data-user-id');
        var receiver_id = $("#auth_id").val();
        var conversation_id = $(this).attr('data-conversation-id');
        //add class active to current conversation to exclude it from notifications
        $(this).addClass('active_conversation');
        startChatWithUser(conversation_id, receiver_id);
        //remove alert sign as new messages already seen
        $("#alert_sign_" + conversation_id).addClass('fa fa-message');
        $("#alert_sign_" + conversation_id).removeClass('fa-solid fa-exclamation');
        $("#startChatButton_" + conversation_id).addClass('btn-primary');
        $("#startChatButton_" + conversation_id).removeClass('btn-warning');
        //listen on current channel
        var newChannel = $(this).attr('data-channel-name'); // Get the new channel name dynamically
        setChannel(newChannel);
    });

});


function startChatWithUser(conversation_id, receiver_id) {
    //set sender as auth id always
    livewire.emit('chatUserSelected', conversation_id, receiver_id);
}

document.addEventListener('check-chat-box-scroll', function () {
    var currentElement = $(".chatbox_body");
    var isScrolledToBottom = currentElement.scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight;

    if (!isScrolledToBottom) {
        $('#scrollChatToBottom').fadeIn(600);
        $('#loadMoreButtonCont').fadeIn(600);
    } else {
        $('#scrollChatToBottom').fadeOut(600);
        $('#loadMoreButtonCont').fadeOut(600);
    }
});

document.addEventListener('open-chat-box', function () {
    $("#kt_drawer_chat_livewire").removeClass('drawer-end');
    $("#kt_drawer_chat_livewire").addClass('drawer-on');
});

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

$(document).on('click', '#scrollChatToBottom', function () {
    livewire.emit('rowChatToBottom');
});

$(document).on('click', '#loadMoreButton', function () {
    $(this).fadeOut(600);
    livewire.emit('loadmore');
});

window.onload = function () {

    const queryString = window.location.search;

    // Parse the query string to get individual parameters
    const urlParams = new URLSearchParams(queryString);

    // Get the value of a specific parameter
    const unreaded_conversation_channel_name = urlParams.get('out_channel_name');
    const unreaded_conversation_id = urlParams.get('conversation_id');
    const unreaded_receiver_id = urlParams.get('receiver_id');

    // Check if both conversation_id and receiver_id are not null
    if (unreaded_conversation_id !== null && unreaded_receiver_id !== null) {
        highlightConversationAsUnreaded(unreaded_conversation_channel_name);
        // Start chat with user
        //startChatWithUser(unreaded_conversation_id, unreaded_receiver_id);
    }

    // Clear query parameters
    const newUrl = window.location.href.split('?')[0];
    history.replaceState({}, document.title, newUrl);

    window.addEventListener('updatedHeight', event => {

        let old = event.detail.height;
        let newHeight = $('.chatbox_body')[0].scrollHeight;

        let height = $('.chatbox_body').scrollTop(newHeight - old);


        window.livewire.emit('updateHeight', {
            height: height,
        });


    });

    window.addEventListener('rowChatToBottom', event => {

        /* $('.chatbox_body').scrollTop($('.chatbox_body')[0].scrollHeight);*/
        $(".chatbox_body").animate({scrollTop: $(document).height()}, 1000, function () {
            $("#scrollChatToBottom").fadeOut(600);
        });

    });


    $(document).on('click', '.return', function () {


        window.livewire.emit('resetComponent');

    });


    window.addEventListener('markMessageAsRead', event => {
        var value = document.querySelectorAll('.status_tick');

        value.array.forEach(element, index => {


            element.classList.remove('bi bi-check2');
            element.classList.add('bi bi-check2-all', 'text-primary');
        });

    });


}
